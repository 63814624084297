import React, { useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import '../styles/edition2024.css';
import { BootcampGalleryImages } from '../../../mocks/bootcamp-gallery';

const Edition2024 = () => {
  const { t } = useTranslation();

  const [shownImages, setShownImages] = useState(6);

  const openImageInNewCard = (src) => {
    window.open(window.location.origin + src, '_blank');
  };

  const showSomeImages = () => {
    if (shownImages < BootcampGalleryImages.length) {
      setShownImages(shownImages + 6);
    } else {
      setShownImages(6);
    }
  };

  const renderImages = () => {
    return BootcampGalleryImages?.map((image, index) => (
      <div
        key={index}
        onClick={() => openImageInNewCard(image.src.default)}
        className={`shade-div ${index < shownImages ? '' : 'hidden'}`}>
        <div className="inside-div">
          <div className="shade-image"></div>
          <img
            className="image"
            src={image.src.default}
            alt="Image"
            placeholder="none"
            loading="lazy"
          />
        </div>
      </div>
    ));
  };

  return (
    <>
      <div className="e2024-container">
        {BootcampGalleryImages && (
          <div className="container">
            <h2 className="h2-style">{t`bootcamp.edition-2024.title`}</h2>
            <div className="images-con">{renderImages()}</div>
            <button onClick={() => showSomeImages()} className="e2024-btn">
              {shownImages < BootcampGalleryImages.length
                ? `${t`bootcamp.edition-2024.button`}`
                : `${t`bootcamp.edition-2024.button-less`}`}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default Edition2024;
