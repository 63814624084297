import React from 'react';
// import './future-academy.css';
import Layout from '../../components/layout/layout';
import Seo from '../../components/seo';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Edition2024 from './components/edition2024';

const Bootcamp = () => {
  const { t } = useTranslation();

  return (
    <Layout withHeader={false}>
      <Seo
        title={t`bootcamp.page-title`}
        description={t`bootcamp.page-description`}
      />
      <Edition2024 />
    </Layout>
  );
};

export default Bootcamp;
